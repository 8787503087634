<template>
  <div class="MyCertificate">
    <el-card class="box_card" v-for="(item, index) in Certificate" :key="index">
      <div class="item">
        <h2>我的证书</h2>
        <p>
          证书名称: <span v-if="item.type == 0">初级网培师证书</span>
          <span v-if="item.type == 1">中级网培师证书</span>
        </p>
        <p>
          颁发日期:<span>{{ item.created_at }}</span>
        </p>
        <p>
          查看证书:
          <el-link type="primary" @click="look(item.cert)">查看</el-link>
        </p>
        <div class="br"></div>
      </div>
    </el-card>
    <div class="tip" v-show="Certificate.length == 0">
      <img src="../../assets/images/user/none.png" alt="" />
      <p class="text-center">暂无证书</p>
    </div>
    <el-dialog :visible.sync="showCert" width="90%">
      <img :src="'http://api.wangpeishi.org.cn/' + cert_url" alt="" />
    </el-dialog>
  </div>
</template>
<script>
let _this
export default {
  data() {
    return {
      Certificate: [],
      showCert: false,
      cert_url: '',
    }
  },
  created() {
    _this = this
    this.getCert()
  },
  methods: {
    getCert() {
      _this.$http
        .get(process.env.VUE_APP_URL + 'certsign')
        .then(data => {
          if (data.data.code == 200) {
            _this.getCertList(data.data.data)
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    getCertList() {
      _this.$http
        .get(process.env.VUE_APP_URL + 'userCert')
        .then(data => {
          if (data.data.code == 200) {
            _this.Certificate = data.data.data
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    look(cert) {
      this.cert_url = cert
      this.showCert = true
    },
  },
}
</script>
<style lang="less" scoped>
.MyCertificate {
  padding: 20px;
  h2 {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  p {
    margin: 12px 0px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    span {
      color: #666666 !important;
      margin-left: 5px;
    }
    a {
      margin-left: 5px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      text-decoration: underline;
      color: #4b8ccc;
    }
  }
  .br {
    width: 80vw;
    height: 1px;
    background: #dadada;
    margin: 0px auto;
  }
  .tip {
    position: absolute;
    top: 30vh;
    left: 0px;
    right: 0px;
    p {
      color: #909399;
      font-size: 20px;
    }
    img {
      width: 100%;
    }
  }
}
</style>
